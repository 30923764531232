import { Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import moment from 'moment';
import {
	BooleanInput,
	Datagrid,
	DateField,
	DateInput,
	FunctionField,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextField,
	useListContext,
	useListFilterContext,
	usePermissions,
	useRecordContext,
	useRedirect,
} from 'react-admin';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType, getPlaceId } from '../../lib';
import { EmployeeType } from '../../models';
import { useEffect, useState } from 'react';
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const TargetField: React.FC<{ label: string, source: string, targetValue: string }> = (props) => {
	const { label, source, targetValue } = props;
	const record = useRecordContext();

	const rowColor = record[source] >= record[targetValue] ? green[600] : 'primary'

	return (
		<FunctionField label={label} render={record => record ? (
			<Box display="flex" flexDirection="row" gap={1}>
				<Typography sx={{ color: rowColor, fontWeight: 'bold' }}>{record[source] >= 0 ? record[source] : '--'}</Typography>
				<Typography sx={{ color: rowColor }}>/</Typography>
				<Typography sx={{ color: rowColor, fontWeight: 'bold' }}>{record[targetValue] >= 0 ? record[targetValue] : '--'}</Typography>
			</Box>
		) : '-/-'} />
	);
}

const AttendantGoalList = ({ isIncompleteFilter }) => {
	const classes = useStyles();
	const props = useListContext();
	const { filterValues } = useListFilterContext();

	const { data } = props;

	const rowKeys = Object.keys(data?.length ? data[0] : {});

	return (
		<>
			<Typography className={classes.title}>Metas por frentistas</Typography>
			{
				(!filterValues.attendantGoalBoardId || !filterValues.stationId || isIncompleteFilter) ?
					<Box padding={2}>
						<Typography>Selecione um posto e um quadro</Typography>
					</Box>
					:
					props.isFetching ?
						<Box padding={2}>
							<Typography>Carregando...</Typography>
						</Box>
						:
						<Datagrid
							resource={props.resource}
							data={data}
							bulkActionButtons={false}
						>
							<TextField source="attendantName" label="Frentista" />
							{
								rowKeys.includes('quantityValue') && rowKeys.includes('quantityTargetMinimumAmount') &&
								<TargetField label="Quantidade" source="quantityValue" targetValue="quantityTargetMinimumAmount" />
							}
							{
								rowKeys.includes('volumeValue') && rowKeys.includes('volumeTargetMinimumAmount') &&
								<TargetField label="Volume" source="volumeValue" targetValue="volumeTargetMinimumAmount" />
							}
							{
								rowKeys.includes('percentageLoyaltyValue') && rowKeys.includes('percentageLoyaltyTargetMinimumAmount') &&
								<TargetField label="% de Fidelidade" source="percentageLoyaltyValue" targetValue="percentageLoyaltyTargetMinimumAmount" />
							}
						</Datagrid>
			}
		</>
	);
}

const FormFilter = ({ handleSetFilters, handleIncompleteFilter }) => {
	const { setValue, watch } = useFormContext();
	const formData = watch();
	const { permissions } = usePermissions();
	const placeId = getPlaceId();

	useEffect(() => {
		if (formData?.date && formData?.stationId && formData?.attendantGoalBoardId) {
			handleSetFilters(formData);
			handleIncompleteFilter(false);
		} else {
			handleIncompleteFilter(true);
		}
	}, [formData]);

	return (
		<Box display="flex" flexDirection="row" gap={2}>
			<DateInput
				source="date"
				label="Data"
				parse={value => value ? dayjs(value).startOf('day').toISOString() : value}
				onChange={() => setValue("attendantGoalBoardId", undefined)}
				alwaysOn
			/>
			{(permissions && permissions.includes(PERMISSIONS.ADMIN)) &&
				<ReferenceInput
					source="chainId"
					reference="chains"
					//@ts-ignore
					perPage={null}
					sort={{ field: "name", order: "ASC" }}
					alwaysOn
				>
					<SelectInput
						emptyText="Selecione uma rede"
						label="Rede"
						style={{ minWidth: 180 }}
						onChange={() => {
							setValue("stationId", undefined);
							setValue("attendantGoalBoardId", undefined)
						}}
						optionText="name"
					/>
				</ReferenceInput>
			}
			{
				!placeId &&
				<ReferenceInput
					source="stationId"
					reference="stations"
					//@ts-ignore
					perPage={null}
					filter={getEmployeeType() === EmployeeType.attendant ? { attendantId: getAttendantId(), chainId: formData.chainId } : { chainId: formData.chainId }}
					sort={{ field: "name", order: "ASC" }}
					alwaysOn
				>
					<SelectInput
						label="Posto"
						emptyText="Selecione um posto"
						onChange={() => setValue("attendantGoalBoardId", undefined)}
						style={{ minWidth: 180 }}
						disabled={!formData.chainId}
						optionText="name"
					/>
				</ReferenceInput>
			}
			<ReferenceInput
				source="attendantGoalBoardId"
				reference="attendant-goal-boards"
				//@ts-ignore
				perPage={null}
				sort={{ field: "name", order: "ASC" }}
				filter={formData.stationId && formData.date ? { date: formData.date, stationId: formData.stationId, chainId: formData.chainId } : { chainId: formData.chainId }}
				alwaysOn
			>
				<SelectInput
					label="Quadro"
					emptyText="Selecione um quadro"
					style={{ minWidth: 180 }}
					optionText={record => `${record.code} - ${record.name}`}
					disabled={!formData.stationId}
				/>
			</ReferenceInput>
			<BooleanInput sx={{ marginBottom: 1.5, marginTop: 1.5 }} source="showAttendantsWithoutProgress" label="Mostrar frentistas sem progresso" alwaysOn />
		</Box>
	);
}

const ListFilters = (props) => {
	const { permissions } = usePermissions();
	const { filterValues, displayedFilters, setFilters } = useListContext();

	const handleSetFilters = (data: any) => {
		setFilters({ ...filterValues, ...data }, displayedFilters, false);
	}

	return (
		<SimpleForm defaultValues={filterValues} toolbar={false} sx={{ padding: 0 }}>
			<FormFilter handleSetFilters={handleSetFilters} handleIncompleteFilter={props.handleIncompleteFilter} />
		</SimpleForm>
	);
}

const FilterTablePrint = () => {
	const { filterValues } = useListContext();
	return (
		<Datagrid data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1} bulkActionButtons={false}>
			<DateField source="date" textAlign="center" label="Date" sortable={false} />
			<Permission permission={PERMISSIONS.ADMIN}>
				<ReferenceField label="Rede" source="chainId" textAlign="center" reference="chains" link={false} >
					<TextField source="name" label="Chain" />
				</ReferenceField>
			</Permission>
			<ReferenceField label="Posto" source="stationId" textAlign="center" reference="stations" link={false} >
				<TextField source="name" label="Posto" />
			</ReferenceField>
			<ReferenceField label="Quadro" source="attendantGoalBoardId" textAlign="center" reference="attendant-goal-boards" link={false} >
				<FunctionField label="Quadro" render={record => record ? `${record.code} - ${record.name}` : 'Não identificado'} />
			</ReferenceField>
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
}

const AttendantGoalBoardMonitor = (props) => {
	const placeId = getPlaceId();
	const [isIncompleteFilter, setIsIncompleteFilter] = useState(true);

	const filterDefaultValues: any = {
		chainId: getChainId(),
		date: dayjs().startOf('day').toISOString(),
	};

	if (placeId) {
		filterDefaultValues.stationId = placeId;
	}


	return (
		<Permission permission={PERMISSIONS.ATTENDANT_TARGET_MONITOR}>
			<ReportList
				{...props}
				title="Monitoramento de quadro"
				resource="attendant-goal-boards/monitor"
				filters={<ListFilters handleIncompleteFilter={setIsIncompleteFilter} />}
				sort={{ field: 'attendantName', order: 'ASC' }}
				filterDefaultValues={filterDefaultValues}
				bulkActionButtons={false}
				customExporter={false}
				titleOnPrint="Monitoramento de quadro"
				filterTablePrint={<FilterTablePrint />}
				showFilterButton
				landscape
				showPagination={false}
				disableSyncWithLocation
				//@ts-ignore
				showFilterButton={null}
			>
				<AttendantGoalList isIncompleteFilter={isIncompleteFilter} />
			</ReportList>
		</Permission>
	);
};

export default AttendantGoalBoardMonitor;