import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	ArrayField,
	BooleanField,
	BooleanInput,
	ChipField,
	Datagrid,
	DateField,
	Filter,
	FormDataConsumer,
	FunctionField,
	NumberField,
	ReferenceArrayField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	SingleFieldList,
	TextField,
	useDataProvider,
	useListContext,
	usePermissions,
	useRecordContext,
} from 'react-admin';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import CheckIfUserHasNotPlaceId from '../../components/CheckIfUserHasNotPlaceId';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getPlaceId, getUserId } from '../../lib';
import { CouponOrigin, EmployeeType, TagType } from '../../models';
import RequiredChain from '../../components/RequiredChain';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
	refArrayInput: {
		minWidth: 180,
		'& .MuiFormHelperText-marginDense': {
			height: 0
		}
	}
}));

const summaryLabel = {
	coupon: 'Cupons',
	value: 'Pontos',
	partner_value: 'Valor',
};

const statusChoices = [
	{
		value: 'not-redeemed',
		name: 'Não resgatado',
	},
	{
		value: 'redeemed',
		name: 'Resgatado',
	},
	{
		value: 'refunded',
		name: 'Estornado',
	},
	{
		value: 'expired',
		name: 'Expirado',
	},
];

const ValueField = (props) => {
	const record = useRecordContext();
	if (!record) { return null; }

	return record.id === 'partner_value' ?
		(
			<NumberField
				{...props}
				record={{ ...record, [props.source]: parseFloat(record[props.source] || 0) }}
				textAlign="center"
				emptyText="R$ 0,00"
				options={{
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				}}
			/>
		)
		: (
			<NumberField
				{...props}
				textAlign="center"
				emptyText="0"
			/>
		);
}

const ProductField = (props) => {
	const record = useRecordContext();
	if (!record) { return null; }

	if (record.title) {
		return (
			<TextField
				source="title"
				sortable={false}
			/>
		)
	}

	if (record.productId) {
		return (
			<ReferenceField
				emptyText="Produto não identificado"
				source="productId"
				basePath="chains/chainId/products"
				reference="chains/chainId/products"
				link="show"
				sortable={false}
				{...props}
			>
				<TextField source="name" />
			</ReferenceField>
		);
	}

	return (
		<FunctionField {...props} render={() => record.isCashback ? 'Cashback' : 'Produto não identificado'} />
	);
}

const CouponsList = () => {
	const classes = useStyles();
	const props = useListContext();
	const { permissions } = usePermissions();

	let data = [];
	let summaryData = [];

	if (props.data) {
		const fillinInfo = props.data[0];

		if (fillinInfo) {
			data = fillinInfo.data;
			summaryData = fillinInfo.summaryData;
		}
	}

	return (
		<>
			<Typography className={classes.title}>Total</Typography>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={3}>
				<FunctionField render={record => summaryLabel[record.id]} />
				<ValueField
					source="redeemed"
					label="Resgatado"
					emptyText="0"
					textAlign="center"
					sortable={false}
				/>
				<ValueField
					source="notRedeemed"
					label="Não resgatado"
					emptyText="0"
					textAlign="center"
					sortable={false}
				/>
				<ValueField
					source="refunded"
					label="Estornado"
					emptyText="0"
					textAlign="center"
					sortable={false}
				/>
				<ValueField
					source="expired"
					label="Expirado"
					emptyText="0"
					textAlign="center"
					sortable={false}
				/>
				<ValueField
					source="total"
					label="Total"
					textAlign="center"
					sortable={false}
				/>
			</Datagrid>

			<Typography className={classes.title}>Cupons</Typography>
			<Datagrid
				bulkActionButtons={false}
				resource={props.resource}
				data={data}
				rowClick={false}
			>
				{
					(permissions && permissions.includes(PERMISSIONS.COUPONS_CODE)) &&
					<TextField source="barcode" label="Cupom" />
				}
				<ProductField label="Produto" />
				<ReferenceField
					label="Origem"
					emptyText="Origem não identificada"
					source="placeId"
					basePath="places"
					reference="places"
					link="show"
					sortable={false}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Local de troca"
					emptyText="Local não identificado"
					source="redemptionPlaceId"
					basePath="places"
					reference="chains/chainId/places"
					link="show"
					sortable={false}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Cliente"
					emptyText="Cliente não identificado"
					source="customerId"
					basePath="customers"
					reference="customers"
					link={!getPlaceId() && "show"}
					sortable={false}
				>
					<TextField source="name" />
				</ReferenceField>
				<NumberField
					source="value"
					label="Pontos"
					textAlign="center"
					emptyText="-"
				/>
				<NumberField
					source="partnerValue"
					label="Valor"
					textAlign="center"
					emptyText="R$ 0,00"
					options={{
						style: 'currency',
						currency: 'BRL',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}}
					sortable={false}
				/>
				{
					CheckIfUserHasNotPlaceId({
						children: (<BooleanField source="redeemed" label="Resgatado" />)
					})
				}
				{
					CheckIfUserHasNotPlaceId({
						children: (<BooleanField source="refunded" label="Estornado" />)
					})

				}
				<DateField source="redeemedAt" label="Data de troca" emptyText="-" textAlign="center" showTime />
				<DateField source="createdAt" label="Data de geração" emptyText="-" textAlign="center" showTime />
			</Datagrid>
		</>
	);
}

const ReportCoupons = ({ status, ...props }) => {
	const [products, setProducts] = useState([]);
	const employeeType = getEmployeeType();
	const dataProvider = useDataProvider();
	const classes = useStyles();

	useEffect(() => {
		dataProvider.getList('chains/chainId/products', {
			filter: { isCash: false, placeId: EmployeeType.place === employeeType ? getPlaceId() : undefined },
			pagination: { page: 1, perPage: null },
			sort: { field: 'name', order: 'ASC' },
		}).then(({ data }) => {
			setProducts([{ id: 'cashback', name: 'Cashback' }].concat(data));
		});
	}, []);

	const couponsExporter = async (data, fetchRelatedRecords) => {
		let couponsInfo = data[0].data;
		const relations = [
			{ field: 'productId', resource: 'chains/chainId/products' },
			{ field: 'placeId', resource: 'places' },
			{ field: 'redemptionPlaceId', resource: 'chains/chainId/places' },
			{ field: 'customerId', resource: 'customers' },
		];

		const fetchData = await fetchRelatedRecords(relations, couponsInfo);

		const getData = (resource) => {
			return fetchData.find(({ resource: res }) => res === resource)?.results || [];
		}

		couponsInfo = couponsInfo.map(coupon => {
			const { productId, placeId, customerId, redemptionPlaceId, isCashback } = coupon;
			const product = getData('chains/chainId/products').find(({ id }) => id === productId);
			const place = getData('places').find(({ id }) => id === placeId);
			const customer = getData('customers').find(({ id }) => id === customerId);
			const redemptionPlace = getData('chains/chainId/places').find(({ id }) => id === redemptionPlaceId);

			return {
				cupom: coupon.barcode,
				produto: isCashback ? 'Cashback' : coupon.title ? coupon.title : product ? product?.name : 'Não identificado',
				origem: place ? place?.name : 'Não identificado',
				local_de_troca: redemptionPlace ? redemptionPlace.name : 'Não identificado',
				cliente: customer ? customer?.name : 'Não identificado',
				pontos: coupon.points,
				valor: coupon.partnerValue,
				resgatado: coupon.redeemed ? 'sim' : 'não',
				estornado: coupon.refunded ? 'sim' : 'não',
				data_de_troca: coupon.redeemedAt,
				data_de_geracao: coupon.createdAt
			};
		});

		return couponsInfo;
	}

	const FilterTablePrint = () => {
		const { filterValues } = useListContext();
		const { permissions } = usePermissions();
		return (
			<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
				<DateField source="from" textAlign="center" label="De" sortable={false} showTime />
				<DateField source="to" textAlign="center" label="Até" sortable={false} showTime />
				<FunctionField label="Situação" render={record => statusChoices.find(item => item.value === record.redeemed)?.name || 'Todos'} />
				<FunctionField label="Produto" render={record => products.find(item => item.id === record.productId)?.name || 'Todos'} />
				{!getPlaceId() &&
					<ReferenceField label="Grupo" emptyText="Todos" source="groupId" textAlign="center" reference="chains/chainId/customer-groups" link={false}>
						<TextField source="name" />
					</ReferenceField>
				}
				{
					permissions && permissions.includes(PERMISSIONS.ADMIN) &&
					<ReferenceField label="Rede de troca" emptyText="Todos" source="chainId" textAlign="center" reference="chains" link={false}>
						<TextField source="name" />
					</ReferenceField>
				}
				{!getPlaceId() &&
					<ReferenceField label="Ponto de troca" emptyText="Todos" source="placeId" textAlign="center" reference="places" link={false}>
						<TextField source="name" />
					</ReferenceField>
				}
				{
					!getPlaceId() && permissions && permissions.includes(PERMISSIONS.TAG) &&
					<ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ReferenceArrayField>
				}
				{
					!getPlaceId() &&
					<ArrayField label="Origem" emptyText="Todas" source="origin" textAlign="center">
						<SingleFieldList>
							<FunctionField render={(record) => (
								<ChipField record={{ name: CouponOrigin.translateOrigin(record) }} source="name" />
							)} />
						</SingleFieldList>
					</ArrayField>
				}
				{
					[EmployeeType.admin, EmployeeType.chain].includes(employeeType) &&
					<ReferenceField label="Usuário" emptyText="Todos" source="redemptionEmployeeId" textAlign="center" reference="_employees" link={false}>
						<TextField source="name" />
					</ReferenceField>
				}
				<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
			</Datagrid>
		);
	}

	const ListFilters = ({ status, ...props }) => {
		return (
			<Filter {...props}>
				{
					!status && CheckIfUserHasNotPlaceId({
						children: (
							<BooleanInput sx={{ height: 36 }} source="useRedeemedDate" label="Usar data de troca" alwaysOn />
						)
					})
				}
				<BetweenDatesTimesInput format={'YYYY-MM-DD HH:mm'} alwaysOn />
				{
					!status && CheckIfUserHasNotPlaceId({
						children:
							(<SelectInput
								choices={statusChoices}
								optionValue="value"
								source="status"
								label="Situação"
								emptyText="Todos"
								alwaysOn
							/>)
					})
				}
				{
					CheckIfUserHasNotPlaceId({
						children: (
							<SelectArrayInput
								label="Origem"
								source="origin"
								choices={CouponOrigin.CouponOriginChoices}
								style={{ minWidth: 160 }}
								alwaysOn
							/>
						)
					})
				}
				<SelectInput choices={products} source="productId" label="Produto" optionText="name" emptyText="Todos" alwaysOn />
				<CheckIfUserHasNotPlaceId alwaysOn>
					<ReferenceInput
						reference="chains/chainId/customer-groups"
						source="groupId"
						sort={{ field: 'name', order: 'ASC' }}
					>
						<SelectInput
							label="Grupo"
							emptyText="Todos"
							optionText="name"
						/>
					</ReferenceInput>
				</CheckIfUserHasNotPlaceId>
				<Permission permission={PERMISSIONS.ADMIN} alwaysOn>
					<ReferenceInput
						reference="chains"
						source="chainId"
						perPage={null}
						sort={{ field: 'name', order: 'ASC' }}
					>
						<SelectInput
							emptyText="Todos"
							label="Rede de troca"
							optionText="name"
						/>
					</ReferenceInput>
				</Permission>
				<CheckIfUserHasNotPlaceId alwaysOn>
					<FormDataConsumer style={{ padding: 0 }}>
						{({ formData }) => {
							return (
								<ReferenceInput
									reference="places"
									source="placeId"
									filter={{ chainId: formData.chainId || getChainId() }}
									sort={{ field: 'name', order: 'ASC' }}
									perPage={null}
								>
									<SelectInput
										emptyText="Todos"
										label="Pontos de troca"
										optionText="name"
									/>
								</ReferenceInput>
							);
						}}
					</FormDataConsumer>
				</CheckIfUserHasNotPlaceId>
				<CheckIfUserHasNotPlaceId alwaysOn>
					<Permission permission={PERMISSIONS.TAG}>
						<FormDataConsumer>
							{({ formData }) => (
								<ReferenceArrayInput
									style={{ minWidth: 180 }}
									source="tagIds"
									reference="chains/chainId/tag"
									perPage={null}
									sort={{ field: "name", order: "ASC" }}
									className={classes.refArrayInput}
									filter={{ type: TagType.product, chainId: formData.chainId }}
								>
									<SelectArrayInput
										label="Etiqueta"
										optionText="name"
									/>
								</ReferenceArrayInput>
							)}
						</FormDataConsumer>
					</Permission>
				</CheckIfUserHasNotPlaceId>
				{
					[EmployeeType.admin, EmployeeType.chain].includes(employeeType) &&
					<FormDataConsumer alwaysOn>
						{({ formData }) => (
							<ReferenceInput
								source="redemptionEmployeeId"
								reference="_employees"
								perPage={null}
								sort={{ field: "name", order: "ASC" }}
								filter={{ isPlace: true, placeId: formData.placeId || undefined }}
							>
								<SelectInput
									label="Usuário"
									optionText="name"
									emptyText="Todos"
								/>
							</ReferenceInput>
						)}
					</FormDataConsumer>
				}
			</Filter>
		)
	}

	const filterValues = {};
	if (EmployeeType.place === employeeType) {
		if (status === 'redeemed') {
			filterValues.redemptionEmployeeId = getUserId();
		}
		filterValues.status = status || 'redeemed';
	}

	return (
		<RequiredChain>
			<ReportList
				{...props}
				basePath="coupons"
				title="Cupons"
				resource="chains/chainId/reports/coupons"
				filters={<ListFilters />}
				filter={filterValues}
				sort={{ field: 'createdAt', order: 'DESC' }}
				filterDefaultValues={{
					chainId: getChainId(),
					from: moment().startOf('day').toISOString(),
					to: moment().endOf('day').toISOString(),
					status,
				}}
				customExporter={couponsExporter}
				bulkActionButtons={false}
				titleOnPrint="Relatório de Cupons"
				filterTablePrint={<FilterTablePrint />}
				landscape
			>
				<CouponsList />
			</ReportList>
		</RequiredChain>
	);
};

export default ReportCoupons;